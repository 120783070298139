<script>
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";
  import { overlay } from "@/state/helpers";
  import Layout from "../../../layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import SettingsSide  from "@/components/settings-side.vue";
  import EmptyTableResult from "@/components/empty-table-result";
  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import { callUrl } from '@/helpers/api-apolo';
  import { saError } from '@/helpers/global-functions';
  import * as helpers from '@/helpers/helper-functions';

  export default {
    page: {
      title: "Campos Customizados",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        title: "Campos Customizados",
        items: [{
            text: "Configurações",
            href: "/"
          },
          {
            text: "Campos Customizados",
            active: true
          },
        ],
        config: {
          mode: "range",
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
        },
        entityNames: {
          product: 'Produto',
          person: 'Pessoa',
          business: 'Negócio'
        },
        typeNames: {
          integer: 'Número (simples)',
          float: 'Número (decimal)',
          string: 'Texto',
          single_enum: 'Opção única',
          multi_enum: 'Múltipla opção',
          date: 'Data',
          datetime: 'Data e hora'
        },
        maskNames: {
          telephone: 'Telefone',
          cpf: 'CPF',
          cnpj: 'CNPJ',
          cep: 'Cep'
        },
        customFieldTypeDisabled: false,
        customFieldEntityDisabled: false,
        customFieldMaskDisabled: false,
        filters: {
          query: '',
          entity: '',
        },
        page: 1,
        perPage: 10,
        dataList: [],
        ids: [],
        activeCustomField: {
          id: '',
          name: '',
          type: '',
          entity: '',
          required: 'false',
          limit_size: '',
          regex_validation: '',
          mask: '',
          order: '',
          placeholder: '',
          value_options: []
        }
      }
    },
    components: {
      Layout,
      PageHeader,
      Multiselect,
      EmptyTableResult,
      SettingsSide
    },
    computed: {
      pages() {
        if (this.page == 1 && !this.dataList.length) {
          return [];
        }
        if (this.page == 1 && this.dataList.length < this.perPage) {
          return [1];
        }
        if (this.page == 1 && this.dataList.length == this.perPage) {
          return [1, 2];
        }
        if (this.page > 1 && (!this.dataList.length || this.dataList.length < this.perPage)) {
          return [this.page - 1, this.page];
        }
        return [this.page - 1, this.page, this.page + 1];
      }
    },
    watch: {
      page() {
        this.getAllCustomFields();
      }
    },
    methods: {
      ...overlay,
      ...helpers,
      translateEntity(value) {
        return this.entityNames[value];
      },
      translateType(value) {
        return this.typeNames[value];
      },
      translateRequired(value) {
        return value ? 'Sim' : 'Não';
      },
      async deletedata(event) {
        Swal.fire({
          title: "Você tem certeza que deseja remover este campo customizado?",
          text: "Não será possível reverter esta ação!",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Sim, remover!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
          customClass: {
            actions: 'd-flex justify-content-center',
            confirmButton: 'btn btn-success flex-grow-1',
            cancelButton: 'btn btn-outline-dark flex-grow-1'
          }
        }).then(async (result) => {
          if (result.value) {
            try {
              this.changeShowOverlay({show: true});
              await callUrl({}, '/custom-field/' + event.id, 'delete')
              this.changeShowOverlay({show: false});
              Swal.fire("Sucesso!", "Removido com sucesso", "success");
              this.getAllCustomFields()
            } catch (error) {
              this.changeShowOverlay({show: false});
              saError(error.message);
            }
          }
        });
      },
      checkIdToDelete(id) {
        if (this.ids.filter(value => value === id).length) {
          this.ids = this.ids.filter(value => value !== id);
          return;
        }
        this.ids.push(id);
      },
      checkAllIdToDelete() {
        this.ids = [];
        const checkboxes = document.querySelectorAll(
          '.form-check-all input[type="checkbox"]'
        );
        if (document.getElementById('checkAll').checked) {
          this.ids = this.dataList.map(obj => obj.id);
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = true;
          });
          return;
        }
        checkboxes.forEach(function (checkbox) {
          checkbox.checked = false;
        });
      },
      deleteMultiple() {
        if (this.ids.length > 0) {
          if (confirm("Você tem certeza que deseja remover todos os registros selecionados?")) {
            // TODO: implementar!
          }
          return;
        }
        Swal.fire({
          title: "Por favor, selecione ao menos um registro para remoção",
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true
        });
      },
      search() {
        if (this.page !== 1) {
          this.page = 1;
        } else {
          this.getAllCustomFields();
        }
      },
      async getAllCustomFields() {
        try {
          this.changeShowOverlay({show: true});
          this.dataList = [];
          const params = [`page=${this.page}`, `limit=${this.perPage}`];
          if (this.filters.query.length > 2) {
            params.push(`query=${this.filters.query}`);
          }
          if (this.filters.entity.length > 0) {
            params.push(`entity=${this.filters.entity}`);
          }
          const dataM = await callUrl({}, '/custom-field?' + params.join('&'), 'get')
          if (dataM && dataM.length) {
            this.dataList = dataM;
          }
        } catch (error) {
          console.log(error.message);
        }
        this.changeShowOverlay({show: false});
      },
      cleanActiveCustomField() {
        Object.keys(this.activeCustomField).forEach(key => {
          let value = '';
          if (key === 'required') {
            value = 'false';
          } else if (key === 'value_options') {
            value = [];
          }
          this.activeCustomField[key] = value;
        });
      },
      addnew() {
        this.cleanActiveCustomField();
        document.getElementById('exampleModalLabel').innerHTML = "Criar novo Campo Customizado";
        document.getElementById('add-btn').style.display = 'block';
        document.getElementById('edit-btn').style.display = 'none';
        this.customFieldTypeDisabled = false;
        this.customFieldEntityDisabled = false;
        this.customFieldMaskDisabled = false;
      },
      editData(item) {
        this.cleanActiveCustomField();
        this.activeCustomField = JSON.parse(JSON.stringify(item));
        this.activeCustomField['required'] = item.required ? 'true' : 'false';
        document.getElementById('exampleModalLabel').innerHTML = "Alterar Campo Customizado";
        document.getElementById('add-btn').style.display = 'none';
        document.getElementById('edit-btn').style.display = 'block';
        this.customFieldTypeDisabled = true;
        this.customFieldEntityDisabled = true;
        this.customFieldMaskDisabled = true;
      },
      validateForm() {
        if (this.activeCustomField.name.length < 3) {
          saError('Nome do campo deve ter ao menos 4 caracteres');
          return false;
        }
        if (!this.activeCustomField.type.length) {
          saError('Tipo do campo é obrigatório');
          return false;
        }
        if (!this.activeCustomField.entity.length) {
          saError('Entidade do campo é obrigatório');
          return false;
        }
        if (this.activeCustomField.required !== 'true' && this.activeCustomField.required !== 'false') {
          saError('Obrigatório definir se o campo é obrigatório');
          return false;
        }
        if (['single_enum', 'multi_enum'].includes(this.activeCustomField.type) && this.activeCustomField.value_options.length < 2) {
          saError('Quando campo for "Opção única" ou "Múltipla opção", deve ser preenchido ao menos 2 opções de valores');
          return false;
        }
        return true;
      },
      async updateCustomField() {
        if (!this.validateForm()) {
          return;
        }
        if (!this.activeCustomField.id.length) {
          return false;
        }
        const customField = this.activeCustomField;
        Object.keys(customField).forEach(key => {
          if (customField[key] === '') {
            delete customField[key];
          }
        });
        try {
          this.changeShowOverlay({show: true});
          await callUrl(customField, '/custom-field/' + this.activeCustomField.id, 'put')
          this.changeShowOverlay({show: false});
          Swal.fire("Sucesso!", "Campo atualizado com sucesso", "success");
          document.getElementById('closemodal').click();
          this.getAllCustomFields()
        } catch (error) {
          this.changeShowOverlay({show: false});
          saError(error.message)
        }
      },
      async addNewCustomField() {
        if (!this.validateForm()) {
          return;
        }
        const customField = this.activeCustomField;
        Object.keys(customField).forEach(key => {
          if (customField[key] === '') {
            delete customField[key];
          }
        });
        try {
          this.changeShowOverlay({show: true});
          await callUrl(customField, '/custom-field', 'post')
          this.changeShowOverlay({show: false});
          Swal.fire("Sucesso!", "campo criado com sucesso", "success");
          document.getElementById('closemodal').click();
          this.getAllCustomFields()
        } catch (error) {
          this.changeShowOverlay({show: false});
          saError(error.message)
        }
      }
    },
    mounted() {
      this.getAllCustomFields();
    },
  };
</script>

<style scoped>
.fieldDisabled {
  background-color: #edeeef !important;
  cursor: not-allowed;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-sm-12 col-lg-2 col-md-3">
          <SettingsSide :actualRoute="'custom_fields'"></SettingsSide>
        </div>
      <div class="col-sm-12 col-lg-10 col-md-9">
        <div class="card" id="customFieldList">

          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Campos de dados</h5>
              <div class="flex-shrink-0">
                <button v-if="ids.length" class="btn btn-soft-danger me-1" @click="deleteMultiple()">
                  <i class="ri-delete-bin-2-line"></i>
                </button>
                <button class="btn btn-success add-btn" data-bs-toggle="modal" href="#showmodal" @click="addnew">
                  <i class="ri-add-line align-bottom me-1"></i> Novo Campo
                </button>
              </div>
            </div>
          </div>
          <div class="card-body bg-soft-light border border-dashed border-start-0 border-end-0">
            <form>
              <div class="row g-3">
                <div class="col-xxl-5 col-sm-6">
                  <div class="search-box">
                    <input type="text" class="form-control search" v-model="filters.query" placeholder="Busca por algum termo...">
                    <i class="ri-search-line search-icon"></i>
                  </div>
                </div>

                <div class="col-xxl-5 col-sm-4">
                  <div class="input-light">
                    <Multiselect class="form-control" v-model="filters.entity" :close-on-select="true" :searchable="true"
                      :create-option="true"
                      :options="[{ value: '', label: 'Todos' }].concat(Object.keys(entityNames).map(key => ({value:key,label:entityNames[key]})))" />
                  </div>
                </div>

                <div class="col-xxl-2 col-sm-2">
                  <button type="button" class="btn btn-primary w-100" @click="search()">
                    <i class="mdi mdi-magnify me-1 align-bottom"></i> Buscar
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="card-body">
            <div>
              <div class="table-responsive table-card">
                <table class="table table-striped align-middle table-nowrap" id="invoiceTable">
                  <thead class="table-primary">
                    <tr>
                      <th scope="col" style="width: 50px;">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="checkAll" value="1" @change="checkAllIdToDelete()">
                        </div>
                      </th>
                      <th class="sort text-uppercase" data-sort="customer_name">Nome</th>
                      <th class="sort text-uppercase" data-sort="country">Entidade</th>
                      <th class="sort text-uppercase" data-sort="email">Tipo</th>
                      <th class="sort text-uppercase" data-sort="invoice_amount">Obrigatório</th>
                      <th class="sort text-uppercase" data-sort="date">Criado em</th>
                      <th class="sort text-uppercase" data-sort="status">Alterado em</th>
                      <th class="sort text-uppercase" data-sort="action">Ações</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all">
                    <tr v-for="(item, index) of dataList" :key="index">
                      <th scope="row">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" name="chk_child" :value="item.id" @change="checkIdToDelete(item.id)" />
                        </div>
                      </th>
                      <td class="customer_name">
                        <div class="d-flex align-items-center">
                          {{item.name}}
                        </div>
                      </td>
                      <td class="country">{{translateEntity(item.entity)}}</td>
                      <td class="email">{{translateType(item.type)}}</td>
                      <td class="invoice_amount">{{translateRequired(item.required)}}</td>
                      <td class="date">{{translateDateOnly(item.created_at)}} <small class="text-muted">{{translateTimeOnly(item.created_at)}}</small></td>
                      <td class="status">{{translateDateOnly(item.updated_at)}} <small class="text-muted">{{translateTimeOnly(item.updated_at)}}</small></td>
                      <td>
                        <div class="dropdown">
                          <button class="btn btn-soft-secondary btn-sm dropdown" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i class="ri-more-fill align-middle"></i>
                          </button>
                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <a class="dropdown-item" data-bs-toggle="modal" href="#showmodal" @click.prevent="editData(item)" style="cursor:pointer">
                                <i class="ri-pencil-fill align-bottom me-2 text-muted"></i>Editar
                              </a>
                            </li>
                            <li class="dropdown-divider"></li>
                            <li>
                              <a class="dropdown-item remove-item-btn" @click.prevent="deletedata(item)" style="cursor:pointer">
                                <i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Remover
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <EmptyTableResult v-show="dataList.length == 0" />

              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="pagination-wrap hstack gap-2">
                  <a class="page-item pagination-prev" href="#" @click="--page" v-if="page > 1">Anterior</a>
                  <a class="page-item pagination-prev disabled" href="#" v-if="dataList.length && page == 1" @click.prevent="">Anterior</a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li v-for="pageNumber in pages" :key="'page' + pageNumber" @click="page = pageNumber"
                      :class="{active: pageNumber == page, disabled: pageNumber != page}">
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a class="page-item pagination-next" href="#" @click="++page" v-if="dataList.length == perPage">Próxima</a>
                  <a class="page-item pagination-next disabled" href="#" @click.prevent="" v-if="dataList.length && dataList.length < perPage">Próxima</a>
                </div>
              </div>
            </div>

            <!-- Modal -->
            <div class="modal fade zoomIn" id="showmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content border-0">
                  <div class="modal-header p-3 bg-soft-info">
                    <h5 class="modal-title" id="exampleModalLabel"></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      id="close-modal"></button>
                  </div>
                  <form id="addform">
                    <div class="modal-body">
                      <div class="row g-3">
                        <input type="hidden" id="id" v-model="activeCustomField.id">
                        <div class="col-lg-6">
                          <label for="customFieldName" class="form-label">Nome *</label>
                          <input type="text" v-model="activeCustomField.name" id="customFieldName" class="form-control" placeholder="Nome do campo" required />
                        </div>
                        <div class="col-lg-6">
                          <label for="customFieldType" class="form-label">Tipo *</label>
                          <Multiselect :class="[{'fieldDisabled': customFieldTypeDisabled}]" :disabled="customFieldTypeDisabled" id="customFieldType" v-model="activeCustomField.type" :close-on-select="true" :searchable="true"
                            :create-option="true"
                            :options="[{ value: '', label: 'Escolha o tipo' }].concat(Object.keys(typeNames).map(key => ({value:key,label:typeNames[key]})))" />
                        </div>
                        <div class="col-lg-6">
                          <label for="customFieldEntity" class="form-label">Entidade *</label>
                          <Multiselect :class="[{'fieldDisabled': customFieldEntityDisabled}]" :disabled="customFieldEntityDisabled" id="customFieldEntity" v-model="activeCustomField.entity" :close-on-select="true" :searchable="true"
                            :create-option="true"
                            :options="[{ value: '', label: 'Escolha a entidade' }].concat(Object.keys(entityNames).map(key => ({value:key,label:entityNames[key]})))" />
                        </div>
                        <div class="col-lg-6">
                          <label class="form-label w-100 mb-3">Obrigatório *</label>
                          <label><input class="form-check-input" type="radio" v-model="activeCustomField.required" name="customFieldEntityTrue" id="customFieldEntityTrue" value="true" /> Sim</label>
                          <label><input class="form-check-input ms-3" type="radio" v-model="activeCustomField.required" name="customFieldEntityFalse" id="customFieldEntityFalse" value="false" /> Não</label>
                        </div>
                        <div class="col-lg-6" v-show="['string', 'integer', 'float'].includes(activeCustomField.type)">
                          <label for="customFieldLimitSize" class="form-label">Limite Tamanho</label>
                          <input type="number" v-model="activeCustomField.limit_size" id="customFieldLimitSize" class="form-control" placeholder="Limite de tamanho do campo" />
                        </div>
                        <div class="col-lg-6" v-show="['string', 'integer', 'float', 'date', 'datetime'].includes(activeCustomField.type)">
                          <label for="customFieldPlaceholder" class="form-label">Placeholder</label>
                          <input type="text" v-model="activeCustomField.placeholder" id="customFieldPlaceholder" class="form-control" placeholder="Text de ajuda para preenchimento do campo" />
                        </div>
                        <div class="col-lg-12" v-show="['string', 'integer', 'float'].includes(activeCustomField.type)">
                          <label for="customFieldRegexValidation" class="form-label">Validação Customizada</label>
                          <input type="text" v-model="activeCustomField.regex_validation" id="customFieldRegexValidation" class="form-control" placeholder="Validação customizada via Regex" />
                        </div>
                        <div class="col-lg-6" v-show="['string'].includes(activeCustomField.type)">
                          <div>
                            <label for="customFieldMask" class="form-label">Máscara do campo</label>
                            <Multiselect :class="[{'fieldDisabled': customFieldMaskDisabled}]" :disabled="customFieldMaskDisabled" id="customFieldMask" v-model="activeCustomField.mask" :close-on-select="true" :searchable="true"
                              :create-option="true"
                              :options="[{ value: '', label: 'Escolha a máscara' }].concat(Object.keys(maskNames).map(key => ({value:key,label:maskNames[key]})))" />
                          </div>
                        </div>
                        <div class="col-lg-12" v-show="['single_enum', 'multi_enum'].includes(activeCustomField.type)">
                          <label for="customFieldValueOptions" class="form-label">Opções de Valores *</label>
                          <Multiselect v-model="activeCustomField.value_options" id="customFieldValueOptions" mode="tags"
                            :close-on-select="true" :searchable="true" :create-option="true"
                            :options="activeCustomField.value_options.map(opt => ({value:opt, label:opt}))"
                            placeholder="Valores disponíveis para preenchimento" />
                        </div>
                        <div class="col-lg-6">
                          <label for="customFieldOrder" class="form-label">Ordenação</label>
                          <input type="number" v-model="activeCustomField.order" id="customFieldOrder" class="form-control" placeholder="Ordem de exibição do campo" />
                        </div>
                      </div>
                      <!--end row-->
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="button" class="btn btn-light" data-bs-dismiss="modal" id="closemodal">Fechar</button>
                        <button type="submit" class="btn btn-success" id="add-btn" @click.prevent="addNewCustomField">Adicionar</button>
                        <button type="button" class="btn btn-success" id="edit-btn" @click.prevent="updateCustomField">Update</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!--end modal-->

          </div>
        </div>

      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
